import { useEffect, useRef, useState } from "react";
import { View, Text, TouchableOpacity, StyleSheet, ScrollView, Image, useWindowDimensions } from "react-native";
import { useFonts } from 'expo-font';
import Icon from "@expo/vector-icons/AntDesign"
import { Entypo } from '@expo/vector-icons';
import { useHover } from "@react-native-aria/interactions";

const PremierePartie = () => {
  return (
    <View style={{ ...styles.mainContainer, ...styles.row }}>
      <View style={{ width: "calc(100vw - 60vh)", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center", paddingRight: "30vh" }}>
        <Text style={{ fontWeight: "700", fontSize: 40 }}>La Piazza</Text>
        <Text style={{ color: "rgb(206, 43, 55)", fontWeight: "500" }}>Ristorante Franco-Italien</Text>
        <TouchableOpacity style={{ backgroundColor: "rgb(206, 43, 55)", padding: 8, borderRadius: 10, marginTop: 15 }}>
          <Text style={{ fontSize: 10, color: "white", fontWeight: "500" }}>Reserver</Text>
        </TouchableOpacity>
      </View>
      <View style={{ backgroundColor: "rgba(206, 43, 55, 1)", width: "60vh", borderBottomLeftRadius: 10 }}>
        <Image source={require("./assets/pizza.png")} style={{ height: "80vh", width: "80vh", position: "absolute", top: "10vh", right: "20vh" }} resizeMode="contain" />
      </View>
    </View>
  )
}

const PartiePhone = ({ width, height }) => {

  const data = {
    Menus: [
      { color: "rgb(0, 146, 70)", titre: "Menu Presto", contenu: "Entrée + Plat OU Plat + Dessert", plats: [["Carpaccio de boeuf", "Carpaccio de saumon", "Tomates mozzarella di bufala"], ["Spaghetti bolognese", "Penne gorgonzola", "Lazagne bolognese", "Calzone soufflée", "Régina"], ["Tiramisu", "Fromage blanc", "Crème brûlée", "2 boules de glace"]] },
      { color: "rgb(206, 43, 55)", titre: "Menu Bambino", contenu: "Plat + Boisson + Dessert", plats: [["Sirop à l'eau", "Capri-Sun"], ["Ravioli bolognese", "escalope de poulet panée frites", "escalope de veau panée frites", "pizza bambino"], ["Glace 2 boules", "fromage blanc"]] },
      { color: "rgb(0, 146, 70)", titre: "Menu Classico", contenu: "Entrée + Plat + Dessert", plats: [["Carpaccio de boeuf", "Carpaccio de saumon", "Tomates mozzarella di bufala", "Assiette de charcuterie"], ["Spaghetti bolognese", "Penne gorgonzola", "Lazagne bolognese", "Penne all'arabiata", "Calzone soufflée", "4 fromages", "Regina"], ["Tiramisu", "Fromage blanc", "Crème brulée", "2 boules de glace"]] }
    ],
    Entrées: [
      { nom: "Carpaccio de boeuf", prix: "10.20€" },
      { nom: "Carpaccio de boeuf, mozzarella di buffala", prix: "12.50€" },
      { nom: "Carpaccio de salmone", prix: "12.50€", description: "Carpaccio de saumon frais aux herbes" },
      { nom: "Chèvre chaud", prix: "10.20€", description: "Salade, tomates, tartnines de chèvre chaud" },
      { nom: "Salade du sud", prix: "11.90€", description: "Aubergines grillées, courgette grillées, tomates séchées, parmesan, huile d'olive, olives" },
      { nom: "Normande", prix: "12.90€", description: "Salade, camembert pané, pommes de terre vapeur, lardons, oignons" },
      { nom: "Burrata", prix: "11.90€", description: "Carpaccio de tomate, pesto, olives, basilic, burrata" },
      { nom: "Tartines gorgonzola chaud", prix: "12.90€", description: "Salade, tartines au gorgonzola chaud, jambon de Parme" },
      { nom: "César", prix: "11.90€", description: "Salade, poulet croustillant, lamelles de parmesan, tomates, croûtons à l'ail" },
      { nom: "Fromagère", prix: "12.90€", description: "Salade, tartine de chèvre, tartine de gorgonzola, mozzarella di bufala, lamelles de parmesan" },

    ],
    Ciabattas: [],
    "Nos pâtes et nos classiques": [],
    Viandes: [],
  }

  const [categorie, setCategorie] = useState(0);
  const [menu, setMenu] = useState(0);
  const [fontsLoaded] = useFonts({
    'Courgette': require("./assets/Courgette/Courgette-regular.ttf"),
  });

  const data2 = [
    { day: "lundi", time: ["fermé"] },
    { day: "mardi", time: ["12:00 - 14:00", "19:00 - 22:30"] },
    { day: "mercredi", time: ["12:00 - 14:00", "19:00 - 22:30"] },
    { day: "jeudi", time: ["12:00 - 14:00", "19:00 - 22:30"] },
    { day: "vendredi", time: ["12:00 - 14:00", "19:00 - 23:00"] },
    { day: "samedi", time: ["12:00 - 14:00", "19:00 - 23:00"] },
    { day: "dimanche", time: ["fermé"] },
  ]

  return (
    <View style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100vw" }}>
      <Text style={{ fontWeight: "700", fontSize: 50, marginTop: 40 }}>La Piazza</Text>
      <Text style={{ color: "rgb(206, 43, 55)", fontWeight: "500", fontSize: 25 }}>Ristorante Franco-Italien</Text>
      <TouchableOpacity style={{ backgroundColor: "rgb(206, 43, 55)", padding: 8, borderRadius: 10, marginTop: 15, marginBottom: 20 }}>
        <Text style={{ fontSize: 15, color: "white", fontWeight: "500" }}>Reserver</Text>
      </TouchableOpacity>
      <Image source={require("./assets/demi-pizza.png")} style={{ height: 0.4 * width, width: 0.8 * width, marginBottom: 15 }} />
      <ScrollView horizontal={true} style={{ width: "100vw", paddingBottom: 15 }} showsHorizontalScrollIndicator={false}>
        {
          Object.keys(data).map((el, i) => {
            return (
              <TouchableOpacity key={i} style={{ marginLeft: i == 0 ? 15 : 0, marginRight: i == Object.keys(data).length - 1 ? 15 : 10, padding: 8, borderRadius: 8, backgroundColor: categorie == i ? "rgb(0, 146, 70)" : "white", borderWidth: categorie == i ? 0 : 0.5, borderColor: "rgb(0, 146, 70)" }} onPress={() => setCategorie(i)}>
                <Text style={{ fontSize: 15, color: categorie == i ? "white" : "black" }}>{el}</Text>
              </TouchableOpacity>
            )
          })
        }
      </ScrollView>
      <View style={{ width: "100vw" }} >
        {
          Object.keys(data)[categorie] == "Menus" ? (
            <View style={{ display: "flex", flexDirection: "row", width: "100vw", justifyContent: "center" }}>
              <View>
                {
                  data["Menus"].map((el, i) => {
                    return (
                      <View key={i} style={{ marginTop: i == 0 ? 15 : 30 }}>
                        <Text style={{ fontFamily: "Courgette", fontSize: 28, marginBottom: 5, color: "rgb(206, 43, 55)", textAlign: "center" }}>{el.titre}</Text>
                        <Text style={{ fontSize: 15, marginBottom: 10 }}>{el.contenu}</Text>
                        {
                          i == 0 ? <Text style={{ fontSize: 14, marginBottom: 10 }}>Seulement le midi</Text> : null
                        }
                        {
                          data["Menus"][i].plats.map((el2, j) => {
                            return (
                              <View key={j}>
                                {data["Menus"][menu].plats[j].map((el3, k) => {
                                  return (
                                    <View style={{ display: "flex", flexDirection: "row" }} key={k}>
                                      {k != 0 ? <Text style={{ fontSize: 15, color: data["Menus"][menu].color }} >ou </Text> : null}
                                      <Text style={{ fontSize: 15 }}>{el3}</Text>
                                    </View>
                                  )
                                })}
                              </View>
                            )
                          })
                        }
                      </View>
                    )
                  })
                }
              </View>
            </View>
          ) : (
            <View>
              <View style={{ width: "100vw", paddingHorizontal: 15, paddingBottom: 15 }} >
                {
                  data[Object.keys(data)[categorie]].map((el, i) => {
                    return (
                      <View key={i}>
                        <View style={{ display: "flex", flexDirection: "row" }} >
                          <Text style={{ fontSize: 15, color: "rgb(0, 146, 70)", fontWeight: "600", marginTop: i != 0 ? 10 : 0 }} >{el.nom}</Text>
                          <Text style={{ fontSize: 15, fontWeight: "600", marginTop: i != 0 ? 10 : 0, color: "rgb(206, 43, 55)" }} > {el.prix}</Text>
                        </View>
                        {
                          el.description ?
                            <Text style={{ fontSize: 12 }}>{el.description}</Text>
                            :
                            null
                        }
                      </View>

                    )
                  })
                }
              </View>
            </View>
          )
        }
      </View>
      <View style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100vw", marginTop: 40, marginBottom: 40 }}>
        <Text style={{ fontWeight: "700", marginBottom: 15, color: "rgb(206, 43, 55)", fontSize: 22 }}>Nos horaires d'ouverture</Text>
        <View>
          <View style={{ width: "calc(100vw - 60px)" }}>
            {data2.map((el, i) => {
              return (
                <View key={i} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 10, alignItems: "center" }}>
                  <Text style={{ fontSize: 15, fontWeight: "600" }}>{el.day}</Text>
                  <View>
                    {
                      data2[i].time.map((el2, j) => {
                        return (
                          <Text key={j} style={{ fontSize: 15, fontWeight: "600", color: "rgb(0, 146, 70)" }}>{el2}</Text>
                        )
                      })
                    }
                  </View>
                </View>
              )
            })}
          </View>
        </View>
      </View>
      <View>
        <PhoneCarte titre={"Nous appeler"} text={"+33 981391002"} bouton={"Appeler"} image={false} icon={"phone"} />
        <PhoneCarte titre={"Nous trouver"} text={`Rue Général Saussier 48,\n10000 Troyes,\nFrance`} bouton={"Voir la localisation"} icon={"location-pin"} image={true} />
        <PhoneCarte titre={"Nous envoyer un email"} text={"stefanoceliamia2014@gmail.com"} bouton={"Envoyer un mail"} image={false} icon={"mail"} middle={true} />
      </View>
    </View>
  )
}

const SecondePartie = () => {
  const data = {
    Menus: [
      { color: "rgb(0, 146, 70)", titre: "Menu Presto", contenu: "Entrée + Plat OU Plat + Dessert", plats: [["Carpaccio de boeuf", "Carpaccio de saumon", "Tomates mozzarella di bufala"], ["Spaghetti bolognese", "Penne gorgonzola", "Lazagne bolognese", "Calzone soufflée", "Régina"], ["Tiramisu", "Fromage blanc", "Crème brûlée", "2 boules de glace"]] },
      { color: "rgb(206, 43, 55)", titre: "Menu Bambino", contenu: "Plat + Boisson + Dessert", plats: [["Sirop à l'eau", "Capri-Sun"], ["Ravioli bolognese", "escalope de poulet panée frites", "escalope de veau panée frites", "pizza bambino"], ["Glace 2 boules", "fromage blanc"]] },
      { color: "rgb(0, 146, 70)", titre: "Menu Classico", contenu: "Entrée + Plat + Dessert", plats: [["Carpaccio de boeuf", "Carpaccio de saumon", "Tomates mozzarella di bufala", "Assiette de charcuterie"], ["Spaghetti bolognese", "Penne gorgonzola", "Lazagne bolognese", "Penne all'arabiata", "Calzone soufflée", "4 fromages", "Regina"], ["Tiramisu", "Fromage blanc", "Crème brulée", "2 boules de glace"]] }
    ],
    Entrées: [
      { nom: "Carpaccio de boeuf", prix: "10.20€" },
      { nom: "Carpaccio de boeuf, mozzarella di buffala", prix: "12.50€" },
      { nom: "Carpaccio de salmone", prix: "12.50€", description: "Carpaccio de saumon frais aux herbes" },
      { nom: "Chèvre chaud", prix: "10.20€", description: "Salade, tomates, tartnines de chèvre chaud" },
      { nom: "Salade du sud", prix: "11.90€", description: "Aubergines grillées, courgette grillées, tomates séchées, parmesan, huile d'olive, olives" },
      { nom: "Normande", prix: "12.90€", description: "Salade, camembert pané, pommes de terre vapeur, lardons, oignons" },
      { nom: "Burrata", prix: "11.90€", description: "Carpaccio de tomate, pesto, olives, basilic, burrata" },
      { nom: "Tartines gorgonzola chaud", prix: "12.90€", description: "Salade, tartines au gorgonzola chaud, jambon de Parme" },
      { nom: "César", prix: "11.90€", description: "Salade, poulet croustillant, lamelles de parmesan, tomates, croûtons à l'ail" },
      { nom: "Fromagère", prix: "12.90€", description: "Salade, tartine de chèvre, tartine de gorgonzola, mozzarella di bufala, lamelles de parmesan" },

    ],
    Ciabattas: [],
    "Nos pâtes et nos classiques": [],
    Viandes: [],
  }

  const [categorie, setCategorie] = useState(0);
  const [menu, setMenu] = useState(0);
  const [fontsLoaded] = useFonts({
    'Courgette': require("./assets/Courgette/Courgette-regular.ttf"),
  });

  return (
    <View style={{ ...styles.mainContainer, ...styles.row }}>
      <View style={{ backgroundColor: "rgba(0, 146, 70, 1)", paddingHorizontal: 15, display: "flex", justifyContent: "center", alignItems: "center", borderTopRightRadius: 10, borderBottomRightRadius: 10 }}>
        <View style={{ display: "flex", justifyContent: "flex-start" }} >
          {Object.keys(data).map((el, i) => {
            return (
              <TouchableOpacity key={i} onPress={() => setCategorie(i)}
                style={{
                  padding: 8,
                  borderRadius: 8,
                  backgroundColor: i == categorie ? "#008000" : null,
                  shadowColor: "#556B2F",
                  shadowOffset: {
                    width: 0,
                    height: 0,
                  },
                  shadowOpacity: i == categorie ? 0.5 : 0,
                  shadowRadius: 5,
                  elevation: 5,
                }}
              >
                <Text style={{ color: "white", fontSize: 12, fontWeight: "600" }}>{el}</Text>
              </TouchableOpacity>
            )
          })}
        </View>
      </View>
      <View style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "calc(100vw - 70vh)", }}>
        {
          Object.keys(data)[categorie] == "Menus" ? (
            <View style={{ display: "flex", flexDirection: "row" }}>
              {menu <= 0 ? null :
                <TouchableOpacity onPress={() => setMenu(menu - 1)} style={{ marginRight: 25 }}>
                  <Entypo name="chevron-small-left" size={24} color="black" />
                </TouchableOpacity>
              }
              <View>
                <Text style={{ fontFamily: "Courgette", textAlign: "center", fontSize: 20, marginBottom: 5, color: "rgb(206, 43, 55)" }}>{data["Menus"][menu].titre}</Text>
                <Text style={{ fontSize: 10 }}>{data["Menus"][menu].contenu}</Text>
                {
                  data["Menus"][menu].plats.map((el2, j) => {
                    return (
                      <View key={j} style={{ marginTop: 10 }}>
                        {data["Menus"][menu].plats[j].map((el3, k) => {
                          return (
                            <View style={{ display: "flex", flexDirection: "row" }} key={k}>
                              {k != 0 ? <Text style={{ fontSize: 10, color: data["Menus"][menu].color }} >ou </Text> : null}
                              <Text style={{ fontSize: 10 }}>{el3}</Text>
                            </View>
                          )
                        })}
                      </View>
                    )
                  })
                }
              </View>
              {menu >= 2 ? null :
                <TouchableOpacity onPress={() => setMenu(menu + 1)} style={{ marginLeft: 25 }}>
                  <Entypo name="chevron-small-right" size={24} color="black" />
                </TouchableOpacity>
              }
            </View>
          ) : (
            <View>
              <ScrollView style={{ width: 280, height: "80vh", paddingHorizontal: 15 }}>
                {
                  data[Object.keys(data)[categorie]].map((el, i) => {
                    return (
                      <View key={i}>
                        <View>
                          <View style={{ display: "flex", flexDirection: "row" }} >
                            <Text style={{ fontSize: 10, color: "rgb(0, 146, 70)", fontWeight: "600", marginTop: i != 0 ? 10 : 0 }} >{el.nom}</Text>
                            <Text style={{ fontSize: 10, fontWeight: "600", marginTop: i != 0 ? 10 : 0, color: "rgb(206, 43, 55)" }} > {el.prix}</Text>
                          </View>
                          {
                            el.description ?
                              <Text style={{ fontSize: 8 }}>{el.description}</Text>
                              :
                              null
                          }
                        </View>
                      </View>
                    )
                  })
                }
              </ScrollView>
            </View>
          )
        }
      </View>
    </View>
  )
}

const TroisièmePartie = () => {

  const data = [
    { day: "lundi", time: ["fermé"] },
    { day: "mardi", time: ["12:00 - 14:00", "19:00 - 22:30"] },
    { day: "mercredi", time: ["12:00 - 14:00", "19:00 - 22:30"] },
    { day: "jeudi", time: ["12:00 - 14:00", "19:00 - 22:30"] },
    { day: "vendredi", time: ["12:00 - 14:00", "19:00 - 23:00"] },
    { day: "samedi", time: ["12:00 - 14:00", "19:00 - 23:00"] },
    { day: "dimanche", time: ["fermé"] },
  ]

  return (
    <View style={{ ...styles.mainContainer, ...styles.row }}>
      <Image source={require("./assets/pizza.png")} style={{ height: "80vh", width: "80vh", position: "absolute", top: "10vh", left: "-40vh" }} resizeMode="contain" />
      <Image source={require("./assets/bascilic.png")} style={{ height: "80vh", width: "80vh", position: "absolute", top: "10vh", transform: "rotate(90deg) translateY(-15vh)", right: 0 }} resizeMode="contain" />
      <View style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100vw" }}>
        <Text style={{ fontWeight: "700", marginBottom: 15, color: "rgb(206, 43, 55)" }}>Nos horaires d'ouverture</Text>
        <View>
          <View style={{ width: 200 }}>
            {data.map((el, i) => {
              return (
                <View key={i} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 10, alignItems: "center" }}>
                  <Text style={{ fontSize: 10, fontWeight: "600" }}>{el.day}</Text>
                  <View>
                    {
                      data[i].time.map((el, j) => {
                        return (
                          <Text key={j} style={{ fontSize: 10, fontWeight: "600", color: "rgb(0, 146, 70)" }}>{el}</Text>
                        )
                      })
                    }
                  </View>
                </View>
              )
            })}
          </View>
        </View>
      </View>
    </View>
  )
}

const PhoneCarte = ({ titre, text, bouton, image, icon, middle }) => {
  return (
    <View style={{
      backgroundColor: "white",
      padding: 10,
      borderRadius: 8,
      shadowColor: "black",
      shadowOffset: {
        width: 0,
        height: 0,
      },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
      display: "flex",
      alignItems: "center",
      marginHorizontal: middle ? 20 : 0,
      marginBottom: 30,
      width: "calc(100vw - 60px)", 
      marginLeft: 25
    }}>
      
      {
        image? <Entypo name={icon} color={"black"} size={60} style={{ marginBottom: 10 }} /> 
        :
        <Icon name={icon} color={"black"} size={60} style={{ marginBottom: 10 }} />
      }
                  
      
      <Text style={{ fontWeight: "700", textAlign: "left", fontSize: 20 }}>{titre}</Text>
      <View style={{ width: "calc(100% - 10px)", backgroundColor: "rgb(206, 43, 55)", height: 1, marginVertical: 10 }} />
      <Text style={{ fontSize: 14, fontWeight: "600", textAlign: "center" }}>{text}</Text>
      <TouchableOpacity style={{ padding: 7, borderRadius: 8, backgroundColor: "rgb(206, 43, 55)", marginTop: 10 }}>
        <Text style={{ fontSize: 14, fontWeight: "600", color: "white" }}>{bouton}</Text>
      </TouchableOpacity>
    </View>
  )
}

const Carte = ({ titre, text, bouton, image, icon, middle }) => {
  return (
    <View style={{
      backgroundColor: "white",
      padding: 10,
      borderRadius: 8,
      shadowColor: "black",
      shadowOffset: {
        width: 0,
        height: 0,
      },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
      display: "flex",
      alignItems: "center",
      marginHorizontal: middle ? 20 : 0
    }}>
      {
        image ? <Entypo name={icon} color={"black"} size={30} style={{ marginBottom: 10 }} /> 
        :
        <Icon name={icon} color={"black"} size={30} style={{ marginBottom: 10 }} />
      }
      <Text style={{ fontWeight: "700", textAlign: "left", fontSize: 12 }}>{titre}</Text>
      <View style={{ width: "calc(100% - 10px)", backgroundColor: "red", height: 0.5, marginVertical: 6 }} />
      <Text style={{ fontSize: 8, fontWeight: "600", textAlign: "center" }}>{text}</Text>
      <TouchableOpacity style={{ padding: 7, borderRadius: 8, backgroundColor: "red", marginTop: 10 }}>
        <Text style={{ fontSize: 8, fontWeight: "600", color: "white" }}>{bouton}</Text>
      </TouchableOpacity>
    </View>
  )
}

const QuatrièmePartie = () => {
  return (
    <View style={{ ...styles.mainContainer, ...styles.row }}>
      <View style={{ width: "100vw", height: "100vh", display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "center" }}>
        <Carte titre={"Nous trouver"} text={`Rue Général Saussier 48,\n10000 Troyes,\nFrance`} bouton={"Voir la localisation"} image={true} icon={"location-pin"} />
        <Carte titre={"Nous envoyer un email"} text={"stefanoceliamia2014@gmail.com"} bouton={"Envoyer un mail"} image={false} icon={"mail"} middle={true} />
        <Carte titre={"Nous appeler"} text={"+33 981391002"} bouton={"Appeler"} image={false} icon={"phone"} />
      </View>
    </View>
  )
}

const NavIndicator = ({ distanceTop, reference, height }) => {

  const [index, setIndex] = useState(0);
  const ref = useRef()
  const { isHovered, hoverProps } = useHover({}, ref);

  useEffect(() => {
    setIndex(Number.parseInt(1 + (distanceTop - height / 2) / height));
  }, [distanceTop])

  return (
    <View {...hoverProps} ref={ref} style={{ position: "absolute", zIndex: 1, right: 15, top: "calc(50vh - 50px)", padding: 10 }}>
      <View style={{ backgroundColor: "white", paddingBottom: 4, paddingHorizontal: 4, borderRadius: 8 }} >
        {
          ["Accueil", "Menu", "Horaires", "Contact"].map((el, i) => {
            return (
              <View key={i}>
                <TouchableOpacity style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }} onPress={() => {
                  reference.current.scrollTo({ y: height * i })
                }}>
                  {
                    isHovered ? <Text style={{ fontSize: 8, marginTop: 4, marginRight: 6, fontWeight: "600" }}>{el}</Text> : null
                  }
                  <View style={{ height: 10, width: 10, borderRadius: 10, backgroundColor: i == index ? "rgb(206, 43, 55)" : "rgba(206, 43, 55, 0.5)", marginTop: 4 }} />
                </TouchableOpacity>
              </View>
            )
          })
        }
      </View>
    </View>
  )
}

const App = () => {

  const [distanceTop, setDistanceTop] = useState(0);
  const ref = useRef(0);

  const { width, height } = useWindowDimensions();

  if (width > 600) {
    return (
      <View>
        <NavIndicator distanceTop={distanceTop} reference={ref} height={height} />
        <ScrollView style={{ height: "100vh" }} scrollEventThrottle={32} onScroll={(e) => setDistanceTop(e.nativeEvent.contentOffset.y)} ref={ref}>
          <PremierePartie />
          <SecondePartie />
          <TroisièmePartie />
          <QuatrièmePartie />
        </ScrollView>
      </View>
    )
  } else {
    return (
      <View>
        <PartiePhone width={width} height={height} />
      </View>
    )
  }
}

export default App;

const styles = StyleSheet.create({
  mainContainer: {
    minHeight: "100vh",
    width: "100vw",
    backgroundColor: "rgb(241, 242, 241)"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  navbar: {
    position: "absolute",
    top: 0,
    zIndex: 1,
    height: 40,
    backgroundColor: "white",
  },
  rightFlag: {
    backgroundColor: "#CE2B37",
    width: "40%",
    minHeight: "100vh"
  },
  mainImage: {
    height: "80vh",
    width: "calc(80vh * 0.8)",
    position: "absolute",
    zIndex: 1,
    right: 0,
    marginTop: "10vh",
    marginRight: "calc(40% - 80vh * 0.4)",
    borderRadius: 5
  }
})